#root {
    #header.guest {
        display: flex;
        justify-content: space-between;

        #calendarButton {
            cursor: pointer;
            display: flex;
            align-items: center;

            .currentDate {
                display: block;
                margin-right: 10px;
            }

            svg {
                margin: 0px;
            }
        }
        #languageButton {
            position: absolute;
            top: 0px;
            right: 225px;
            font-size: 18px;
            height: 100%;
            width: 55px;
            box-sizing: border-box;
            background-color: #666;
            line-height: 55px;
            text-align: center;
            cursor: pointer;
            opacity: 1;
            transition: opacity 0.3s linear;
        }
    }
}
