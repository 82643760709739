@import '../../../scss/base';

.register {
    overflow: hidden;
    background-color: $color-third;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 40px;
    .registerBox {
        background-color: $color-second;
        padding: 0px 20px 20px 20px;
        box-shadow: 5px 5px 40px $color-fourth;
        min-width: 280px;
        max-width: 380px;
        flex: 1 1;
    }
 }
