@import "../../../scss/base";

#countDownTimer {
    width: 200px;
    height: 200px;
    border-radius: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-background);
    border: 20px solid var(--color-background-20);
    font-weight: bold;
    font-size: 20px;

    & > div {
        text-align: center;

        .total {
            font-size: 20px;
        }
    }

    &.running {
        font-weight: bold;
        color: #00ff00;
    }

    &.runningBackward {
        background-color: #f00;
        border-color: darken(#f00, 20);
        cursor: pointer;
    }

    &.timerStopped {
        background-color: #f00;
        border-color: darken(#f00, 20);
        cursor: pointer;
        font-size: 20px;
    }

    &.idle {
        background-color: var(--color-main);
        border-color: var(--color-main-darken-10);
        color: $color-second;
        font-size: 40px;
        font-weight: bold;
        cursor: pointer;

        div {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        &:hover {
            border-color: var(--color-main-darken-5);
        }
    }

    &.alert {
        background-color: #f00;
        color: #fff;
        border-color: darken(#f00, 20);
        cursor: pointer;

        div {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.complete .dx-progressbar-range {
    background-color: red;
    border-color: red;
}
