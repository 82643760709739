@import "../../../../scss/base";

#main.home .nav {
    display: none;
}

#main.home #content {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    // background-color: white;
}

#tilesContainer {
    display: flex;
    justify-content: center;

    flex-direction: column;
    width: fit-content;
    padding: 30px;

    & nav {
        align-items: center;
    }

    .tiles {
        max-width: 800px;
        flex-wrap: wrap;
        flex-shrink: 0;

        &.single-tile {
            width: 134px;
        }

        &.single-column {
            width: 100%;
        }

        header {
            color: $color-second;
            font-size: 22px;
            margin-bottom: 12px;
        }

        & > div {
            .navigationTile,
            a {
                display: block;
                margin-right: 24px;
                width: 134px;
                height: 134px;
                background-color: $color-second;
                margin-top: 16px;
                margin-bottom: 24px;
                float: left;
                text-align: center;
                color: $color-fourth;
                text-decoration: none;
                padding: 22px;
                font-family: Lato, sans-serif;

                svg {
                    width: 60px;
                    height: 60px;
                }

                span {
                    display: block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}

#tilesContainer {
    .tiles {
        .navigationTile,
        a {
            opacity: 0;
            transition: opacity 0.3s linear;
        }
    }

    .tiles {
        .revealed {
            .navigationTile,
            a {
                opacity: 1;
            }
        }
    }
}
