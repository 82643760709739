@import "../../../../scss/base";

#cookingProcess > #StepPage {
    align-items: stretch;

    .content-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .dx-loadpanel {
        margin: 0px;
    }

    .box {
        width: 100%;

        &.empty {
            opacity: 0.3;
        }
    }

    .grammage {
        width: 10px;
        text-align: right;
    }

    .unit {
        width: 30px;
    }

    .box.instructions {
        flex: 1;
        display: flex;
        flex-direction: column;

        & > header {
            display: flex;
            justify-content: flex-end;
        }

        .box-content {
            flex: 1;
            display: flex;
            align-items: stretch;
            margin: 0px -10px;

            .box-content-item {
                padding: 0px 10px;
                width: calc(100% / 3);

                table tr td {
                    line-height: 100%;
                }
            }
        }
    }

    .timer-box {
        height: 100%;

        header {
            color: $color-fourth;
            padding-top: 20px;
            margin: 0px 20px;
            font-weight: bold;
        }

        .content {
            background-color: transparent;
            display: flex;
            justify-content: center;
            padding-top: 0px;
            height: 100%;
            align-items: center;
        }
    }

    .inner-box-currentStep {
        padding: 10px 0px;
    }

    .inner-box-stepsDone {
        opacity: 0.5;
    }

    .inner-box {
        background-color: $color-second;
        height: 100%;
        font-size: 21px;

        header {
            color: $color-fourth;
            padding-top: 20px;
            margin: 0px 20px;
            font-weight: bold;
        }

        .inner-box-content {
            color: $color-fourth;
            padding: 20px;
        }

        &.text {
            flex: 1;

            .inner-box-content {
                padding: 0px;
            }
        }
    }

    .ingredients {
        flex: 1;
    }

    .inner-box.tool {
        padding: 10px;

        .inner-box-content {
            color: $color-fourth;
        }

        table {
            width: 100%;
        }
    }

    .inner-box.instruction {
        padding: 10px;

        .inner-box-content {
            color: $color-fourth;
        }

        table {
            width: 100%;
        }
    }

    .inner-box.ingredients {
        padding: 10px;

        .inner-box-content {
            color: $color-fourth;
        }

        table {
            width: 100%;
        }
    }

    .top {
        margin-bottom: 20px;

        & > .box {
            min-height: 100%;
        }
    }

    .bottom {
        flex: 1;
        width: 100%;
        display: flex;
        align-items: stretch;
        justify-content: stretch;
        overflow: hidden;
        margin-bottom: 20px;

        .box-wrapper-left {
            width: calc(100% / 3);
            background-color: lighten($color-third, 10);
            padding: 10px;
        }

        .box-wrapper-middle {
            width: calc(100% / 3);
            background-color: lighten($color-third, 10);
            padding: 10px;
            margin: 0px 10px;
        }

        .box-wrapper-right {
            width: calc(100% / 3);
            background-color: lighten($color-third, 10);
            padding: 10px;
        }

        .hint {
            width: 100%;
            height: 100%;
        }

        .image {
            width: 100%;
            min-width: auto;
            min-height: 250px;
            padding: 10px;
            margin-bottom: 20px;
            height: 100%;
            display: flex;
            justify-content: stretch;
            align-items: stretch;

            .content {
                background-size: contain;
                background-position: center center;
                background-repeat: no-repeat;
            }
        }
    }

    .box.empty {
        display: flex;
        align-items: stretch;

        .content {
            display: flex;
            align-items: center;
            justify-content: center;

            i {
                font-size: 100px;
            }
        }
    }

    .box.timer {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        height: 100%;

        .content {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

@media screen and (max-width: 768px) {
    #cookingProcess > #StepPage.content .box > header > div {
        display: flex !important;
    }

    #cookingProcess > #StepPage .box.instructions > header {
        justify-content: stretch;
    }

    #cookingProcess > .content .box header .allSteps {
        flex: 1;
        padding: 10px;
    }

    #cookingProcess > .content .box header .finished {
        flex: 1;
    }

    #cookingProcess > #StepPage .box.instructions .box-content {
        flex-direction: column;
    }

    #cookingProcess > #StepPage .inner-box.ingredients {
        padding: 10px;
    }

    #cookingProcess > #StepPage .inner-box header {
        font-size: 16px;
        padding: 0px;
    }

    #cookingProcess > #StepPage .inner-box.ingredients .inner-box-content {
        padding: 0px;
    }

    #cookingProcess > #StepPage .inner-box {
        font-size: 14px;
    }

    #cookingProcess > #StepPage .inner-box.text {
        padding: 10px;
    }

    #cookingProcess > #StepPage .inner-box header {
        margin: 0px 0px 10px 0px;
    }

    #cookingProcess > #StepPage .box.instructions .box-content .box-content-item {
        margin-bottom: 10px;
        width: 100%;

        &:last-child {
            margin-bottom: 0px;
        }
    }

    #cookingProcess > #StepPage .bottom {
        flex-direction: column-reverse;
    }

    #cookingProcess > #StepPage .bottom .box-wrapper-left {
        width: 100%;
        padding: 0px;
    }

    #cookingProcess > #StepPage .bottom .box-wrapper-middle {
        width: 100%;
        padding: 0px;
    }

    #cookingProcess > #StepPage .bottom .box-wrapper-right {
        width: 100%;
        padding: 0px;
    }

    #cookingProcess > #StepPage .bottom .hint {
        margin-bottom: 10px;
    }

    #cookingProcess > #StepPage .bottom .image {
        margin-bottom: 10px;
    }

    #cookingProcess > #StepPage .bottom .timer {
        margin-bottom: 10px;
    }

    #cookingProcess > .content .box {
        margin-bottom: 10px;
    }

    #cookingProcess > #StepPage .top {
        margin-bottom: 0px;
    }

    #cookingProcess > #StepPage .bottom {
        margin-bottom: 0px;
    }

    #cookingProcess > #StepPage .box.empty {
        display: none;
    }

    #cookingProcess > .content .box .stepsDone {
        padding: 0px;
        background-color: transparent;
    }

    #cookingProcess > #StepPage .inner-box.ingredients .inner-box-content tr {
        vertical-align: top;
    }
}
