﻿$color-second: var(--color-background);
$color-third: #333;
$color-fourth: #000;
$color-fifth: #404040;
$color-sixth: #cecece;

:root {
    /** Cart */
    --color-cart-background: #fff;
    --color-cart-border: #ccc;
    --color-cart-comment-indicator: #4caf50;
    --color-cart-danger: #dc3545;
    --color-cart-shadow: #cecece 3px 3px 6px;
    --color-cart-warning: #ffc107; // Alternative: #ffcccc;
    /* Media */
    --color-media-background: #fff;
    --color-media-border: #ccc;
    --color-media-comment-indicator: #4caf50;
    --color-media-danger: #dc3545;
    --color-media-uploadzone: #6f6f6f;
    --color-media-uploadzone-overlay: rgba(255, 255, 255, 0.8);
    --color-media-shadow: #cecece 3px 3px 6px;
    --color-media-warning: #ffc107; // Alternative: #ffcccc;
}
