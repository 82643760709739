.export-modal-content {
    padding: 20px;

    .export-description {
        margin-bottom: 20px;
        color: #6c757d;
    }

    .filter-form {
        margin-top: 20px;

        .form-group {
            margin-bottom: 15px;

            label {
                display: block;
                margin-bottom: 5px;
                color: #495057;
                font-size: 0.9rem;
            }
        }
    }

    .modal-buttons {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        margin-top: 30px;

        :global(.dx-button) {
            min-width: 120px;
        }

        :global(.dx-button-success) {
            background-color: #00bcd4;
            border-color: #00bcd4;

            &:hover {
                background-color: darken(#00bcd4, 5%);
            }
        }
    }
}