@import "../../../scss/base";

#cookingProcess {
    color: $color-second;
    background-color: $color-third;
    padding: 20px;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;

    & > header {
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        margin-bottom: 20px;
        flex-grow: 0;

        h1 {
            margin-top: 0px;
            margin-bottom: 0px;
        }

        h2 {
            margin: 0px;

            span {
                font-size: 16px;
            }
        }
    }

    & > .content {
        flex-grow: 1;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        .left {
            width: 70%;
            padding-right: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
        }

        .right {
            width: 30%;
            padding-left: 10px;
        }

        .box {
            overflow: hidden;
            min-width: 50%;
            background-color: lighten($color-third, 10);
            padding: 10px;
            margin-bottom: 20px;

            header {
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;
                align-items: center;

                .allSteps {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 10px 20px;
                    font-size: 16px;
                    cursor: pointer;
                }

                .finished {
                    background-color: var(--color-main);
                    padding: 10px 5px 10px 20px;
                    height: 100%;
                    font-weight: bold;
                    font-size: 20px;
                    text-transform: uppercase;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                }

                .finishedDisabled {
                    background-color: $color-third;
                    padding: 10px 5px 10px 20px;
                    height: 100%;
                    font-weight: bold;
                    font-size: 20px;
                    text-transform: uppercase;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: not-allowed;
                }
            }

            .ingredients {
                background-color: $color-second;
                margin-bottom: 5px;
                padding: 20px;
                color: $color-fourth;
            }

            .currentStep {
                background-color: $color-second;
                margin-bottom: 5px;
                padding: 20px;
                color: $color-fourth;
            }

            .stepsDone {
                background-color: gray;
                margin-bottom: 5px;
                padding: 20px;
                color: lighten($color-fourth, 70);
            }

            &.image {
                padding: 0px;
                line-height: 0px;
                border: none;

                .content {
                    padding: 0px;
                }
            }

            &.timer {
                header {
                    h3 {
                        margin-bottom: 0px;
                    }
                }

                .content {
                    background-color: transparent;
                    display: flex;
                    justify-content: center;
                    padding-top: 0px;
                }
            }

            &.ingredients {
                table {
                    width: 100%;

                    tr {
                        vertical-align: top;
                        td {
                            padding: 5px 0px;

                            &.grammage {
                                width: 50px;
                                text-align: right;
                                padding-right: 2px;
                            }

                            &.unit {
                                width: 15px;
                                padding-right: 10px;
                            }
                        }

                        &:first-child {
                            td {
                                border-top: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

#finishPhase {
    p {
        margin-top: 0px;
    }

    .finishButtons {
        display: flex;
        justify-content: space-between;
    }
}

#skipTimer {
    p {
        margin-top: 0px;
    }

    .skipTimerButtons {
        display: flex;
        justify-content: space-between;
    }
}

@media screen and (max-width: 768px) {
    #cookingProcess {
        padding: 10px;
    }

    #cookingProcess > header {
        padding: 5px;
    }

    #cookingProcess > header h1 {
        font-size: 21px;
        margin-bottom: 5px;
    }

    #cookingProcess > header h2 {
        font-size: 16px;
        font-weight: 400;
    }

    #cookingProcess > .content {
        display: block;
    }

    #cookingProcess > .content .left {
        width: 100%;
        padding: 0px;
    }

    #cookingProcess > .content .box {
        width: 100%;
    }

    #cookingProcess > .content .box > header > div {
        display: block !important;
        width: 100%;
    }

    #cookingProcess > .content .box header .finished {
        padding: 10px 0px 10px 10px;
        font-size: 16px;
    }

    #cookingProcess > .content .box .ingredients {
        padding: 10px;
    }

    #cookingProcess > .content .box .ingredients .ingredients {
        padding: 0px;
    }

    #cookingProcess > .content .box header h3 {
        margin: 0px;
        font-size: 16px;
    }
}
