.tse-container {
    padding: 20px;

    .tse-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      gap: 30px;
      margin-bottom: 40px;

      .info-card {
        background: #f8f9fa;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0,0,0,0.05);
        transition: transform 0.2s;

        &:hover {
          transform: translateY(-2px);
        }

        h3 {
          color: #2c3e50;
          margin-bottom: 12px;
          font-size: 1.1rem;
          font-weight: 600;
        }

        .content {
          display: flex;
          flex-direction: column;
          gap: 15px;

          span {
            font-size: 0.95rem;
            color: #495057;
            word-break: break-all;
          }
        }

        &.status { background-color: #e3f2fd; }
        &.id { background-color: #f3e5f5; }
        &.clients { background-color: #e8f5e9; }
        &.signature { background-color: #fff3e0; }
        &.dates { background-color: #fce4ec; }
        &.certificate { background-color: #e8eaf6; }
      }
    }

    .button-group {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;

      :global(.dx-button) {
        border-radius: 6px;
        min-height: 36px;

        &.dx-button-success {
          background-color: #00bcd4;
          border-color: #00bcd4;

          &:hover {
            background-color: darken(#00bcd4, 5%);
          }
        }
      }
    }

    .clients-section {
      background: white;
      border-radius: 12px;
      padding: 24px;
      box-shadow: 0 4px 6px rgba(0,0,0,0.1);
      margin-top: 40px;

      h3 {
        color: #2c3e50;
        margin-bottom: 20px;
        font-weight: 600;
      }

      .client-table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0;

        th, td {
          text-align: center;
          padding: 12px;
          border-bottom: 1px solid #e9ecef;
          white-space: nowrap;
        }

        th {
          background-color: #f8f9fa;
          color: #495057;
          font-weight: 600;
          font-size: 0.85rem;
          letter-spacing: 0.5px;

          &:first-child {
            border-top-left-radius: 8px;
          }

          &:last-child {
            border-top-right-radius: 8px;
          }
        }

        td {
          color: #495057;
          font-size: 0.9rem;
          background-color: white;
        }

        tbody tr {
          &:hover td {
            background-color: #f8f9fa;
          }

          &:last-child td {
            border-bottom: none;
          }
        }
      }
    }

    .exports-section {
        background: white;
        border-radius: 4px;
        padding: 20px;
        margin-top: 20px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

        .section-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 15px;

            h3 {
                margin: 0;
            }
        }

        .exports-table {
            width: 100%;
            border-collapse: collapse;

            th, td {
                padding: 8px;
                text-align: left;
                border-bottom: 1px solid #ddd;
            }

            th {
                background-color: #f5f5f5;
            }

            td:last-child {
                text-align: center;

                .dx-button {
                    min-width: 120px;
                    padding: 5px 10px;
                }
            }
        }

        .pagination-container {
            margin-top: 20px;
            display: flex;
            justify-content: center;

            :global(.dx-pages) {
                .dx-page {
                    padding: 6px 12px;
                    margin: 0 2px;
                    border-radius: 4px;

                    &.dx-selection {
                        background-color: #00bcd4;
                        color: white;
                    }
                }
            }
        }
    }
  }

.pagination-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;

    :global(.dx-pages) {
        .dx-page {
            padding: 6px 12px;
            margin: 0 2px;
            border-radius: 4px;

            &.dx-selection {
                background-color: #00bcd4;
                color: white;
            }
        }
    }
}

.exports-table {
    width: 100%;
    margin-bottom: 20px;
}

.tse-selector {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 20px;

    .create-tse-button {
        flex-shrink: 0;
    }
}