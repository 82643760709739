@import "../../../../scss/base";

#navigation {
    background-color: #ddd;
    height: 100%;
    overflow: auto;

    .dx-item-content {
        background-color: "#eee";
        .dx-treeview {
            margin: 10px 10px 10px 50px;
            background-color: #ddd;
            padding: 5px 10px;
        }
    }
    img {
        width: 35px;
        opacity: 0.8;
    }

    .dx-list-item-content,
    .dx-list .dx-empty-message {
        padding: 10px;
    }
    .dx-list-item.dx-state-hover,
    .dx-list-item.dx-state-active,
    .dx-list-item.dx-state-focused {
        background-color: #ddd !important;
        color: initial !important;
    }
    .active-route {
        background-color: #ddd !important;
    }
}

#content {
    .nav {
        display: none;
    }
}

.subNavigationMenu {
    margin-left: 20px;
}

.active-route {
    background-color: #ddd !important;
    color: initial !important;
}

.navContainer {
    overflow: hidden;
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.4s linear;

    height: 0;
    &.open {
        transform: scaleY(1);
        height: auto;
        transition: transform 0.4s ease-out;
    }
}
