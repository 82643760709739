@import "../../../scss/_base.scss";

.dx-fa-button.dx-fa-button-main[title="abgeschlossene Kochprozesse"] .dx-overlay-content {
    background-color: red;

    .dx-fa-button-icon {
        height: auto;
    }
}

.dx-datagrid-revert-tooltip {
    display: none;
}

.groupedCookingProcessesCustomer {
    // display: flex;
    margin-right: 10px;
}

.cookingProcessCustomerPreferredCosumingDay {
    color: white;
    text-align: center;
    font-size: 24px;
    margin: 25px;
}

.cookingProcessCustomerHeader {
    background-color: $color-third;
    display: flex;
}

.calendarIcon {
    color: white;
    font-size: 40px !important;
    margin: 30px;
    width: 10%;
}

.cookingProcessCustomerHeader {
    padding: 15px;
}

.cookingProcessCustomerWelcomeText {
    color: white;
    margin: 0;

    .title {
        display: block;
        font-weight: bold;
        font-size: 21px;
        margin-bottom: 5px;
    }
}

.requestedDeliveryDay {
    color: green;
}

.cookingProcessCompleted {
    background-color: lighten($color-fourth, 90);
    padding: 10px 15px;
    margin-top: 10px;

    p {
        margin: 0px;

        &.titleText {
            font-weight: bold;
        }
    }
}

#allCookingProcessesOverview {
    position: relative;

    #cookingProcessesCompleted {
        position: absolute;
        right: 0px;
        top: 0px;
    }

    // #cookingProcessesCompleted {
    //     width: 20%;
    //     padding: 20px;
    //     overflow: auto;

    //     #headerText {
    //         color: $color-second;
    //     }

    //     #cookingProcessEndtime {
    //         color: $color-second;
    //     }

    //     #cookingProcessCompleted {
    //         width: 300px;
    //         padding: 10px;
    //         border: 1px solid darken($color-second, 60);
    //         background-color: darken($color-second, 70);
    //         margin-bottom: 10px;
    //         flex-shrink: 0;
    //         border-radius: 5px;
    //     }

    //     #titleText {
    //         font-size: 22px;
    //         color: $color-second;
    //     }

    //     #portionNumberText {
    //         font-size: 18px;
    //         color: $color-second;
    //     }
    // }

    #tilesContainer {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        padding: 10px !important;
        // max-width: 80%;
        width: 100%;

        #tiles {
            width: 400px;
            padding: 20px;
            background-color: var(--color-background-20);
            margin: 25px;
            flex-shrink: 0;
            border-radius: 5px;

            header {
                font-size: 22px;
                margin-bottom: 20px;
                color: $color-third;
            }

            .cookingProcessButtons {
                display: flex;
                justify-content: center;
                margin: 5px 5px 20px 5px;
            }

            .cookingProcessButton {
                background-color: var(--color-main);
                color: $color-second;
                font-size: 12px;
                margin: 0px 10px 0px 0px;
                width: 40px;
                height: 40px;
                border-radius: 5px;
                cursor: pointer;
            }

            .buttonIcon {
                position: relative;
                left: 25%;
                top: 25%;
            }

            .content {
                h3 {
                    margin-top: 0px;
                    margin-bottom: 20px;
                    color: $color-third;
                    font-weight: normal;
                }

                .step {
                    margin-bottom: 10px;
                    border-radius: 5px;
                    cursor: pointer;
                }
            }

            & > div {
                padding-bottom: 10px;
                width: 350px;

                TextArea {
                    font-size: 15;
                    margin-bottom: 15px;
                    color: $color-fourth;
                    display: flex;
                    justify-content: center;
                    cursor: default;
                }

                a {
                    display: block;
                    width: 134px;
                    height: 134px;
                    background-color: $color-second;
                    margin-bottom: 8px;
                    float: left;
                    text-align: center;
                    color: $color-fourth;
                    text-decoration: none;
                    padding: 22px;

                    svg {
                        width: 60px;
                        height: 60px;
                    }

                    &:nth-child(2n) {
                        margin-left: 8px;
                    }

                    span {
                        display: block;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }

                #actualStep {
                    color: $color-fourth;
                }

                #step {
                    font-size: 12px;
                    margin-bottom: 15px;
                    color: $color-fourth;
                    display: flex;
                    justify-content: center;
                }

                #actualPhase {
                    font-size: 20px;
                    margin-bottom: 10px;
                    margin-left: 10px;
                    color: $color-fourth;
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }

    #tilesContainer {
        #tiles {
            a {
                opacity: 0;
                transition: opacity 0.3s linear;
            }
        }

        #tiles {
            .AllRevealed {
                a {
                    opacity: 1;
                }
            }
        }
    }
}

#iconPopups {
    p {
        margin-top: 0px;
    }

    .popupButtons {
        display: flex;
        justify-content: space-between;
    }
}

#pies-container {
    justify-content: center;
    display: flex;
    margin: 20px;
    margin-left: 100px;
    height: 150px;
    width: 150px;
}

.parentCookingProcess {
    background-color: $color-third;
    padding: 15px 30px 0px 30px;

    .title {
        color: $color-second;
        margin: 0px;
    }
}

.cookingProcessForm {
    .phaseType {
        color: $color-second;
        padding: 5px 20px;
        text-transform: uppercase;
        font-weight: bold;
        margin: 0px -20px 20px -20px;
        border-radius: 5px 5px 0px 0px;
        font-size: 12px;
    }
}

.cookingProcessesCalendarPopup {
    .dx-popup-content {
        padding: 10px;
    }
}

#allCookingProcessesOverview.guest {
    height: auto;
    flex: 1;

    .tilesContainerWrapper {
        padding: 10px;
    }

    #tilesContainer {
        box-sizing: border-box;
        width: 100%;
        padding: 0px !important;

        & > div {
            width: 100%;
        }

        .groupedCookingProcessesCustomer {
            margin-right: 0px;
        }

        #tiles {
            margin: 0px;
            width: 100%;
            padding: 10px;
            border-width: 5px !important;

            & > div {
                width: auto;
            }

            header {
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 15px;
            }

            .cookingProcessComptact {
                border-width: 5px !important;
            }

            .cookingProcessComptact header h3 {
                font-weight: bold;
            }

            .cookingProcessComptact header h3,
            .content h3 {
                font-size: 16px;
            }

            .cookingProcessButtons {
                margin-bottom: 15px;
            }

            .stepText {
                font-size: 14px;
            }
        }
    }
}

#cookingProcessCustomer {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
}

// @media screen and (min-width: 768px) {

//     .swiper-container,
//     .swiper-container .swiper-slide {
//         flex: 1;
//     }
// }
