@import "../../../scss/base";

#main.home .nav {
    display: none;
}

.stepDone {
    opacity: 0.5;
    background-color: $color-second;
}

.stepOpen {
    background-color: $color-second;
}

.stepTextContainer {
    display: flex;
    justify-content: space-between;
}

.stepText {
    padding: 10px;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.stepDescription {
    font-weight: bold;
}

.stepNumber {
    background-color: var(--color-main);
    color: $color-second;
    padding: 5px 10px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 900;
    border-radius: 5px 5px 0px 0px;
}

.stepButton {
    width: 40px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.statusIcon {
    width: 30px;
    height: 20px;
}

.countDownTimer {
    margin: 10px;
}

.blinker1 {
    -webkit-animation: 2s linear infinite blinkerYellow; /* for Safari 4.0 - 8.0 */
    animation: 2s linear infinite blinkerYellow;
}
.blinker2 {
    -webkit-animation: 2s linear infinite blinkerOrange; /* for Safari 4.0 - 8.0 */
    animation: 2s linear infinite blinkerOrange;
}
.blinker3 {
    -webkit-animation: 2s linear infinite blinkerRed; /* for Safari 4.0 - 8.0 */
    animation: 2s linear infinite blinkerRed;
}

/* for Safari 4.0 - 8.0 */
@-webkit-keyframes blinkerOrange {
    0% {
        background-color: orange;
    }
    50% {
        background-color: orange;
    }
    100% {
        background-color: white;
    }
}

@keyframes blinkerOrange {
    0% {
        background-color: orange;
    }
    50% {
        background-color: orange;
    }
    100% {
        background-color: white;
    }
}
/* for Safari 4.0 - 8.0 */
@-webkit-keyframes blinkerRed {
    0% {
        background-color: red;
    }
    50% {
        background-color: red;
    }
    100% {
        background-color: white;
    }
}

@keyframes blinkerRed {
    0% {
        background-color: red;
    }
    50% {
        background-color: red;
    }
    100% {
        background-color: white;
    }
}
/* for Safari 4.0 - 8.0 */
@-webkit-keyframes blinkerYellow {
    0% {
        background-color: yellow;
    }
    50% {
        background-color: yellow;
    }
    100% {
        background-color: white;
    }
}

@keyframes blinkerYellow {
    0% {
        background-color: yellow;
    }
    50% {
        background-color: yellow;
    }
    100% {
        background-color: white;
    }
}
