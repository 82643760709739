@import "variables";
@import "@fontsource/lato/100.css";
@import "@fontsource/lato/100-italic.css";
@import "@fontsource/lato/300.css";
@import "@fontsource/lato/300-italic.css";
@import "@fontsource/lato/400.css";
@import "@fontsource/lato/400-italic.css";
@import "@fontsource/lato/700.css";
@import "@fontsource/lato/700-italic.css";
@import "@fontsource/lato/900.css";
@import "@fontsource/lato/900-italic.css";

// :root {
//     --color-main: #669421;
//     --color-main-darken-5: #567d1c;
//     --color-main-darken-10: #486917;
// }

:root {
    --color-main: hsl(var(--accent-color));
    --color-main-darken-5: hsl(var(--accent-color-dl-6));
    --color-main-darken-10: hsl(var(--accent-color-dl-10));
    --color-background: hsl(var(--background-color));
    --color-background-o08: hsla(var(--background-color), 0.8);
    --color-background-10: hsl(var(--background-color-dl-10));
    --color-background-20: hsl(var(--background-color-dl-20));
    --color-background-30: hsl(var(--background-color-dl-30));
    --color-text: hsl(var(--text-color));
    --color-border: hsl(var(--border-color));
    --color-tiles: hsl(var(--tiles-color));
    --color-tiles-background: hsl(var(--tiles-background-color));
    --color-header-background: hsl(var(--header-background-color));
    --color-footer-background: hsl(var(--footer-background-color));
    --color-button-background: hsl(var(--button-background-color));
    --color-button-background-hover: hsl(var(--button-background-color-hover));
    --color-button-background-active: hsl(var(--button-background-color-active));
}

* {
    box-sizing: border-box;
}

body {
    background-color: $color-third;
    font-family: "Lato", sans-serif;
}

#root {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    height: 100vh;
}

.nav {
    background-color: $color-second;
    flex: 0 1;

    nav {
        height: 100%;
        width: 100%;
        overflow-y: auto;
    }
}

#main {
    #content {
        background-color: $color-second;
    }

    &.home {
        #content {
            background-color: transparent;
        }
    }
}

.buttonDialog {
    p {
        margin-top: 0px;
        // font-weight: bold;
    }

    .buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.draggable {
    cursor: grab;
}

.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.dx-datagrid-headers {
    background-color: #eee;
}

.dx-datagrid-headers {
    .dx-datagrid-filter-row .dx-editor-cell .dx-editor-with-menu .dx-texteditor-input,
    .dx-datagrid-filter-row .dx-editor-cell .dx-editor-with-menu .dx-placeholder:before {
        background-color: #eee;
    }
}

body .dx-datagrid-headers .dx-editor-cell .dx-texteditor .dx-texteditor-input {
    background-color: #eee;
}

.dx-datagrid-headers .dx-texteditor-buttons-container {
    background-color: #eee;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.player-wrapper {
    position: relative;
    padding-top: 56.25%;
    /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
}

.disabledTile {
    pointer-events: none;
    background-color: #666 !important;
}

.disabledNavigationTile {
    pointer-events: none;
}

.dx-button-mode-contained.dx-button-default.dx-button-custom {
    background-color: var(--color-button-background);
    &.dx-state-hover {
        background-color: var(--color-button-background-hover);
    }
    &.dx-state-active,
    &.dx-state-focused {
        background-color: var(--color-button-background-active);
    }
}
