.about-this-page {
    padding: 20px;
    height: 100%;
  }

  .about-this-page_tab-panel {
    height: 100%;
  }

  .popupActions {
    margin: 10px;
    display: flex;
    justify-content: flex-end;
  }

  .html-editor-container {
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
    overflow: auto;
    max-height: 600px;
  }

  .html-editor {
    min-height: 200px;
    border: 1px solid #ccc;
    padding: 10px;
  }

  button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
  }

  button:hover {
    background-color: #0056b3;
  }
