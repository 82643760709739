@import "../../../scss/variables";

#CurrentTimers {
    .dx-loadindicator-icon .dx-loadindicator-segment {
        background-color: $color-second;
    }
}

#header {
    #CurrentTimersLoading {
        background-color: lighten($color-third, 5);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 20px;

        .dx-loadindicator-icon .dx-loadindicator-segment {
            background: $color-second;
        }
    }

    #CurrentTimers {
        background-color: lighten($color-third, 5);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 66px 0px 20px;
        position: relative;

        .timer {
            max-width: 220px;

            h2 {
                font-size: 12px;
                margin: 0px 0px 2px 0px;
                display: block;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                span {
                    color: var(--color-background-30);
                }
            }

            .countdown {
                .dx-widget {
                    .dx-progressbar-status {
                        color: $color-second;
                        font-size: 10px;
                        float: right;
                        height: auto;
                    }
                }
            }
        }

        .singleCookingProcessButton {
            position: absolute;
            right: 20px;

            .dx-button {
                background-color: var(--color-main);
            }
        }

        .moreTimers {
            position: absolute;
            right: 20px;
            top: 0px;
            bottom: 0px;
            display: flex;
            align-items: center;

            .dx-button-mode-contained.dx-button-success {
                background-color: var(--color-main);
            }

            .badge {
                position: absolute;
                bottom: 5px;
                right: -7px;
                background-color: lighten($color-fourth, 40);
                font-size: 12px;
                padding: 1px;
                border-radius: 30px;
                min-width: 17px;
                text-align: center;
            }
        }
    }
}

#cookingTimersList {
    h2 {
        font-size: 12px;
        margin: 0px;
    }

    .dx-progressbar-status {
        float: right;
        height: auto;
        font-size: 10px;
    }

    .cookingTimersListItem {
        margin-top: 5px;
        background-color: lighten($color-fourth, $amount: 90);
        padding: 5px 10px;
        display: flex;

        &.first {
            margin-top: 0px;
        }

        .dx-button {
            margin-left: 10px;
            background-color: var(--color-main);
        }

        .timer {
            flex: 1;
        }
    }
}

.dx-popup-wrapper.moreTimersPopover {
    .dx-popup-content {
        padding: 10px;
    }

    & > .dx-overlay-content {
        border-radius: 0px;
    }
}
