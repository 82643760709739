@import "../../../scss/base";

#root {
    #header {
        background-color: var(--color-header-background);
        padding: 6px 10px;
        box-sizing: border-box;
        position: absolute;
        display: "flex";
        justify-content: "center";
        align-items: "center";
        top: 0px;
        z-index: 2;
        left: 0px;
        right: 0px;
        flex: 0 0 55px;
        color: $color-second;
        height: 52px;

        a {
            color: $color-second;
        }

        #headerNav {
            position: absolute;
            bottom: 10px;
            left: 20px;
            color: $color-second;
            font-size: 30px;
            width: 200px;

            #back {
                float: left;
                margin-right: 42px;
            }
        }

        #headerContextNav {
            position: absolute;
            top: 15px;
            right: 25px;
            color: $color-second;
            font-size: 20px;
        }

        #logo {
            display: flex;
            font-size: 30px;
            font-weight: 600;
            text-decoration: none;
            margin-left: 2px;
            height: 100%;
            display: flex;
            align-items: center;

            img {
                height: 40px;
                width: auto;
                margin-right: 10px;
            }
        }

        #reloadButton {
            position: absolute;
            top: 0px;
            right: 0px;
            font-size: 18px;
            height: 100%;
            width: 55px;
            box-sizing: border-box;
            background-color: #666;
            line-height: 55px;
            text-align: center;
            cursor: pointer;
            opacity: 1;
            transition: opacity 0.3s linear;
        }

        #languageButton {
            position: absolute;
            top: 0px;
            right: 55px;
            font-size: 18px;
            height: 100%;
            width: 55px;
            box-sizing: border-box;
            background-color: #666;
            line-height: 55px;
            text-align: center;
            cursor: pointer;
            opacity: 1;
            transition: opacity 0.3s linear;
        }

        #navigationButton {
            position: absolute;
            bottom: 10px;
            font-size: 30px;
            right: 20px;
            cursor: pointer;
            display: none;
        }

        #userMenu {
            position: absolute;
            top: 0px;
            right: 110px;
            height: 100%;
            overflow: hidden;
            background-color: #555;
            padding: 0px 10px 0px 0px;
            display: flex;
            opacity: 1;
            transition: opacity 0.3s linear;

            & > .dx-menu {
                margin-left: 20px;
            }

            .dx-menu-base .dx-menu-item .dx-menu-item-content .dx-menu-item-popout-container .dx-menu-item-popout {
                display: none;
            }
        }

        .dx-menu .dx-menu-item-has-text.dx-menu-item-has-submenu .dx-menu-item-text {
            font-size: 16px;
            color: $color-second;
            font-weight: bold;
        }

        .dx-menu:hover .dx-menu-item-has-text.dx-menu-item-has-submenu .dx-menu-item-text {
            color: grey;
            font-weight: bold;
        }

        .dx-menu .dx-menu-item-has-text.dx-menu-item-expanded .dx-menu-item-text {
            color: grey;
            font-weight: bold;
        }

        .dx-menu-base {
            font-family: "Lato", sans-serif;
        }
    }
}

#root #header.not-logged-in {
    #userMenu {
        opacity: 0;
    }

    #reloadButton {
        opacity: 0;
    }
}

#root #header #logo.sandbox.kronen img {
    height: 42px;
    position: relative;
    top: -6px;
}

#root #header #logo.sandbox.kronen #logoText {
    position: relative;
    top: -1px;
}

@media screen and (max-width: 767px) {
    #reloadButton {
        display: none;
    }

    #userMenu {
        display: none;
    }

    #root #header #languageButton {
        right: 0px;
    }

    #root #header #userMenu {
        right: 55px;
    }

    #root #header .dx-menu .dx-menu-item-has-text.dx-menu-item-has-submenu .dx-menu-item-text {
        display: none;
    }

    #root #header #userMenu > .dx-menu {
        margin-left: 10px;
    }

    #root #header .dx-menu .dx-menu-item-has-text.dx-menu-item-has-icon .dx-icon {
        color: white;
    }

    #root #header .dx-menu:hover .dx-menu-item-has-text.dx-menu-item-has-icon .dx-icon {
        color: grey;
    }

    #root #header #logo #logoText {
        display: none;
    }
}

@media screen and (min-width: 768px) {
    #root #header .dx-menu .dx-menu-item-has-text.dx-menu-item-has-icon .dx-icon {
        display: none;
    }
}

#root #header #logo.sandbox {
    #logoText {
        margin-top: 0;
        margin-left: 20px;
        font-size: 70%;
    }

    img {
        height: 35px;
        margin-left: 3px;
    }
}

.dx-popup-content {
    .language-button {
        .dx-button-content {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
