@import "../../../../scss/variables";

#allCookingProcessesOverview #tilesContainer #tiles {
    .cookingProcessComptact {
        padding: 10px 15px 10px 15px;
        background-color: lighten($color-third, 15);
        border-radius: 5px;
        // border: 1px solid var(--color-main);
        margin-bottom: 20px;

        .phaseType {
            margin: 0px -15px 15px -15px;
        }

        header {
            font-size: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 15px;

            h3 {
                margin-bottom: 0px;
                font-size: 20px;
                font-weight: normal;
                padding-right: 10px;
                color: $color-second;
            }

            a {
                width: 40px;
                height: 40px;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                float: none;
                opacity: 1;
                background-color: var(--color-main);
                color: $color-second;
                padding: 0px;
                margin: 0px;

                svg {
                    width: 30px;
                    height: auto;
                }
            }

            .cookingProcessCompactHeaderButtons {
                display: flex;
            }
        }

        .info {
            h3 {
                color: $color-second;
            }
        }

        .cookingProcessCompactInfo {
            color: $color-second;

            .step {
                .stepNumber {
                    // display: none;
                }

                .stepTextContainer {
                    color: $color-third;
                }
            }
        }
    }

}