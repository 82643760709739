@import "../../../../scss/base";

.email{
    margin: 10px;
}

.password{    
    margin: 10px;
}

