@import "../../../scss/base";

.login {
    overflow: hidden;
    background-color: var(--color-background);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 40px;
    .loginBox {
        background-color: var(--color-background-10);
        padding: 0px 20px 20px 20px;
        border-width: 2px;
        border-style: solid;
        border-color: var(--color-border);

        min-width: 280px;
        max-width: 380px;
        flex: 1 1;
    }
}
