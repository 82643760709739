#themeLoading {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: var(--color-background);
    z-index: 9;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: bottom 0s 0s, opacity 0s 0s linear;

    &.hidden {
        transition: bottom 0s 1s, opacity 1s 0s linear;
        bottom: 100%;
        opacity: 0;
    }
}